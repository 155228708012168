import { Box } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import queryString from 'query-string'
import { FunctionComponent, ImgHTMLAttributes } from 'react'
import { toSxArray } from 'lib/utils'

interface Props extends ImgHTMLAttributes<'img'> {
  cover?: boolean
  sx?: SxProps<Theme>
}

const Img: FunctionComponent<Props> = ({
  src,
  alt,
  cover = true,
  className,
  sx = [],
}) => {
  const coverStyles = {
    display: 'block',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  }

  return src.endsWith('svg') ? (
    <Box
      component="img"
      src={src}
      alt={alt}
      sx={[cover && coverStyles, ...toSxArray(sx)]}
    />
  ) : (
    <Box component="picture" className={className} sx={sx}>
      <source
        srcSet={queryString.stringifyUrl({ url: src, query: { fm: 'webp' } })}
        type="image/webp"
      />
      <Box component="img" src={src} alt={alt} sx={[cover && coverStyles]} />
    </Box>
  )
}

export default Img
