import {
  AppBar,
  Box,
  Container,
  GlobalStyles,
  SxProps,
  Theme,
  ThemeProvider,
  Toolbar,
  useScrollTrigger,
  useTheme,
} from '@mui/material'
import dynamic from 'next/dynamic'
import { FunctionComponent, ReactElement, useContext, useEffect } from 'react'
import { useElementSize } from 'usehooks-ts'
import { colors } from 'lib/root'
import { darkTheme, lightTheme } from 'lib/theme'
import { toSxArray } from 'lib/utils'
import zIndexes from 'lib/zIndexes'
import BurgerButton from './BurgerButton'
import Link from './Link'
import Logo from './Logo'
import { AppContext } from './contexts/AppContext'

const MicrositeMobileMenu = dynamic(() => import('./MicrositeMobileMenu'))

interface NavItem {
  url: string
  label: string
}

const logoStyles = () => [
  {
    display: 'flex',
    zIndex: zIndexes.header,
    color: colors.core.grey[600],
    '& svg': {
      width: { xs: 68, sm: 90 },
      height: { xs: 24, sm: 32 },
      fill: 'currentColor',
    },
    '&:hover': {
      color: colors.core.blue[800],
    },
    '&:active': {
      color: colors.core.blue[800],
    },
  },
]

interface Props {
  navItems: NavItem[]
  logoLink?: ReactElement
  ctaButton?: ReactElement
  sx?: SxProps<Theme>
}

const MicrositeHeader: FunctionComponent<Props> = ({
  navItems,
  logoLink,
  ctaButton,
  sx = [],
}) => {
  const { mobileMenuIsShown, toggleMobileMenuIsShown, setHeaderHeight } =
    useContext(AppContext)
  const theme = useTheme()
  const pastTopOfPage = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })
  const [headerRef, { height }] = useElementSize()

  useEffect(() => {
    setHeaderHeight(height)

    return () => setHeaderHeight(0)
  }, [height, setHeaderHeight])

  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyles
        styles={{
          body: {
            overflow: mobileMenuIsShown ? 'hidden' : 'visible',
          },
        }}
      />
      <AppBar
        ref={headerRef}
        position="sticky"
        color={!pastTopOfPage ? 'transparent' : 'primary'}
        elevation={0}
        sx={[
          {
            transition: 'background-color 0.5s ease',
          },
        ]}
      >
        <Container
          component={Toolbar}
          maxWidth="xl"
          disableGutters
          style={{
            height: !pastTopOfPage ? 96 : 72,
          }}
          sx={[
            {
              display: 'flex',
              zIndex: zIndexes.header,
              color: colors.core.paper,
              py: 0,
              transition: 'height 0.2s linear',
              [theme.breakpoints.down('sm')]: {
                maxHeight: 72,
              },
            },
            ...toSxArray(sx),
          ]}
        >
          {logoLink ? (
            logoLink
          ) : (
            <Link
              href="/"
              underline="none"
              aria-label="Tend"
              sx={[
                ...logoStyles(),
                mobileMenuIsShown && { color: theme.palette.common.white },
              ]}
            >
              <Logo />
            </Link>
          )}
          <Box
            component="nav"
            sx={{
              ml: 5,
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              '& a': {
                position: 'relative',
                color: theme.palette.secondary.main,
                fontSize: '1.125rem',
                fontWeight: 500,
                '&:hover': {
                  color: colors.core.blue[800],
                  textDecoration: 'none',
                },
                '&:active, &.active': {
                  color: colors.core.blue[800],
                },
              },
              '& a + a': {
                pl: 3,
              },
            }}
          >
            {navItems.map((navItem) => (
              <Link href={navItem.url} key={navItem.url}>
                {navItem.label}
              </Link>
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'auto',
              marginLeft: 'auto',
            }}
          >
            <ThemeProvider theme={mobileMenuIsShown ? darkTheme : lightTheme}>
              {ctaButton}
              <BurgerButton
                aria-label="menu"
                onClick={toggleMobileMenuIsShown}
                active={mobileMenuIsShown}
                width={20}
                color={
                  mobileMenuIsShown
                    ? theme.palette.common.white
                    : colors.core.grey[700]
                }
                lineHeight={2}
                lineSpacing={3}
                sx={{
                  zIndex: zIndexes.header,
                  display: { md: 'none !important' },
                }}
              />
              {mobileMenuIsShown && (
                <MicrositeMobileMenu
                  navItems={navItems}
                  sx={[{ backgroundColor: colors.core.green[600] }]}
                />
              )}
            </ThemeProvider>
          </Box>
        </Container>
      </AppBar>
    </ThemeProvider>
  )
}

export default MicrositeHeader
