import { Box, ThemeProvider } from '@mui/material'
import type { FunctionComponent, ReactElement, ReactNode } from 'react'
import { useContext } from 'react'
import { CAREERS_URL } from 'lib/constants'
import { darkTheme } from 'lib/theme'
import MicrositeHeader from 'components/MicrositeHeader'
import SkipNavLink from 'components/SkipNavLink'
import { AppContext } from 'components/contexts/AppContext'
import Footer from '../Footer'

const CONTENT_ID = 'content'

interface Props {
  children: ReactNode
  footerPhoneNumber?: string
  categories?: { url: string; label: string }[]
}

const CareersLayout: FunctionComponent<Props> = ({
  children,
  categories,
  footerPhoneNumber,
}) => {
  const { headerHeight } = useContext(AppContext)

  const navItems = [
    { url: CAREERS_URL, label: 'Careers' },
    ...categories,
    { url: '/careers/jobs', label: 'Open Jobs' },
  ]

  return (
    <>
      <Box component="header" display="contents">
        <SkipNavLink contentId={CONTENT_ID} />
        <MicrositeHeader navItems={navItems} sx={[{ px: { md: 8 } }]} />
      </Box>

      <Box
        component="main"
        id={CONTENT_ID}
        sx={{
          minHeight: `calc(100vh - ${headerHeight}px)`,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </Box>

      <ThemeProvider theme={darkTheme}>
        <Footer phoneNumber={footerPhoneNumber} />
      </ThemeProvider>
    </>
  )
}

export const getLayout = (page: ReactElement) => {
  const { footerPhoneNumber } = page.props

  return (
    <CareersLayout footerPhoneNumber={footerPhoneNumber}>{page}</CareersLayout>
  )
}

export default CareersLayout
