import { Box, styled, SxProps, Theme } from '@mui/material'
import { FunctionComponent, ReactNode } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { toSxArray } from 'lib/utils'
import DownArrow from './icons/DownArrow'

export const TendAccordion = styled(Accordion)({})

export const TendAccordionItem = styled(AccordionItem)({})

interface TendAccordionSummaryProps {
  children: ReactNode
  hideIcon?: boolean
  expandIcon?: ReactNode
  sx?: SxProps<Theme>
}

export const TendAccordionSummary: FunctionComponent<
  TendAccordionSummaryProps
> = ({ children, hideIcon = false, expandIcon, sx = [] }) => (
  <AccordionItemHeading>
    <Box
      component={AccordionItemButton}
      sx={[
        {
          display: 'flex',
          justifyContent: 'space-between',
          '&:hover': { cursor: 'pointer' },
          '&[aria-expanded="true"] .MuiSvgIcon-root, &[aria-selected="true"] .MuiSvgIcon-root':
            {
              transform: 'rotate(180deg)',
            },
        },
        ...toSxArray(sx),
      ]}
    >
      {children}
      {!hideIcon && (expandIcon ?? <DownArrow />)}
    </Box>
  </AccordionItemHeading>
)

export const TendAccordionItemPanel = styled(AccordionItemPanel)({
  animation: 'fadein 0.35s ease-in',
  '@keyframes fadein': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
})
