import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  TextField,
  Theme,
  Typography,
  styled,
} from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { escapeRegExp } from 'lodash'
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { AccordionItemPanel } from 'react-accessible-accordion'
import { ID } from 'react-accessible-accordion/dist/types/components/ItemContext'
import { nantes, foundersGrotesk } from 'lib/fonts'
import { colors } from 'lib/root'
import {
  TendAccordion,
  TendAccordionItem,
  TendAccordionSummary,
} from './AccordionControls'
import Img from './Img'
import Link from './Link'
import ScrollAnimate from './ScrollAnimate'
import DownArrow from './icons/DownArrow'
import SearchIcon from './icons/SearchIcon'

export type JobFiltersType = {
  location?: string
  category?: string
  search?: string
}

export type JobListingsEntry = {
  id: string | number
  title: string
  category: string
  location: string
  office: string
  url: string
}

export type JobBoardProps = {
  title: string
  subTitle: string
  images?: string[]
  initialFilters?: JobFiltersType
  jobs: JobListingsEntry[]
  showCategoryFilter?: boolean
  onFilterChanged?: (filters: JobFiltersType) => void
  divider?: boolean
  expanded?: boolean
}

type JobFiltersProps = {
  locations: string[]
  categories?: string[]
  search?: string
  filters: JobFiltersType
  onChange: (filters: JobFiltersType) => void
}

type JobListingsProps = {
  jobs: JobListingsEntry[]
  pageSize?: number
  expanded?: boolean
}

type JobsPerCategoryType = {
  category: string
  uuid: string
  jobs: JobListingsEntry[]
}

type CategoryParamsType = {
  [category: string]: { limit: number; className: string; expanded: boolean }
}

const imageStyles: SxProps<Theme>[] = [
  { width: '52%', aspectRatio: 165 / 148, zIndex: 0 },
  {
    width: '40%',
    aspectRatio: 129 / 142,
    ml: '12%',
    zIndex: 0,
  },
  {
    width: '54%',
    aspectRatio: 170 / 208,
    ml: '46%',
    top: '50%',
    transform: 'translateY(-50%)',
    position: 'absolute',
    zIndex: 1,
  },
]

const menuItemStyles = {
  fontSize: '1.125rem !important',
  color: colors.core.grey[700],
  fontWeight: 500,
  bgcolor: colors.defaults.white,
  '&:hover, &:focus-visible': {
    color: colors.core.grey[700],
    bgcolor: colors.core.grey[200],
    outline: 'none',
  },
  '&.Mui-selected, &.Mui-selected:hover': {
    bgcolor: `${colors.core.grey[700]} !important`,
    color: colors.defaults.white,
  },
}

const JobFilters = ({
  locations,
  categories,
  filters,
  onChange,
}: JobFiltersProps) => {
  const onLocationChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const location = event.target.value || ''

      onChange({ ...filters, location })
    },
    [filters, onChange],
  )

  const onCategoryChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const category = event.target.value || ''

      onChange({ ...filters, category })
    },
    [filters, onChange],
  )

  const onSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const search = event.target.value
      onChange({ ...filters, search })
    },
    [filters, onChange],
  )

  return (
    <Grid
      container
      columnGap={2}
      rowGap={2}
      flexDirection={{ xs: 'column', md: 'row' }}
    >
      <Grid
        container
        item
        direction={'column'}
        sx={{
          flexBasis: { xs: 'auto', md: 0 },
          flexGrow: 1,
          minWidth: 0,
          alignItems: 'stretch',
        }}
      >
        <Typography
          component={'label'}
          sx={{ fontSize: { xs: '0.875rem' }, width: '100%' }}
        >
          Location:
        </Typography>
        <Select
          IconComponent={DownArrow}
          value={
            (locations.includes(filters.location) && filters.location) || ['']
          }
          onChange={onLocationChange}
          sx={{
            '.MuiSelect-select:focus': { pl: 0, outline: 'none' },
            width: '100%',
          }}
          MenuProps={{
            MenuListProps: {
              sx: { py: 0, backgroundColor: colors.defaults.white },
            },
          }}
        >
          <MenuItem value={''} sx={menuItemStyles}>
            All
          </MenuItem>
          {locations.map((location) => (
            <MenuItem key={location} value={location} sx={menuItemStyles}>
              {location}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {categories && (
        <Grid
          container
          item
          direction={'column'}
          sx={{ flexBasis: { xs: 'auto', md: 0 }, flexGrow: 1, minWidth: 0 }}
        >
          <Typography
            component={'label'}
            sx={{ fontSize: { xs: '0.875rem' }, width: '100%' }}
          >
            Category:
          </Typography>
          <Select
            IconComponent={DownArrow}
            value={
              (categories.includes(filters.category) && filters.category) || [
                '',
              ]
            }
            onChange={onCategoryChange}
            sx={{
              '.MuiSelect-select:focus': { pl: 0, outline: 'none' },
              width: '100%',
            }}
            MenuProps={{
              MenuListProps: {
                sx: { py: 0, backgroundColor: colors.defaults.white },
              },
            }}
          >
            <MenuItem value={''} sx={menuItemStyles}>
              All
            </MenuItem>
            {categories.map((category) => (
              <MenuItem key={category} value={category} sx={menuItemStyles}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}
      <Grid
        container
        item
        direction={'column'}
        sx={{
          flexBasis: { xs: 'auto', md: 0 },
          flexGrow: { xs: 'auto', md: 1 },
          minWidth: 0,
          height: 60,
        }}
      >
        <TextField
          sx={{
            mt: 'auto',
            width: '100%',
            'input::placeholder': { opacity: 1, color: colors.core.grey[700] },
            '.MuiInput-input:focus': { pl: 0, outline: 'none' },
          }}
          placeholder="Search"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={filters.search || ''}
          onChange={onSearchChange}
        />
      </Grid>
    </Grid>
  )
}

const JobItem = ({
  job,
  animate = true,
}: {
  job: JobListingsEntry
  animate: boolean
}) => (
  <Grid
    container
    key={job.id}
    component={motion.li}
    flexDirection={'row'}
    justifyContent={'space-between'}
    alignItems={'center'}
    sx={{
      pt: 1,
      pb: 2,
      borderBottom: `1px solid ${colors.core.grey[400]}`,
      '&:last-child': { borderBottom: 'none' },
      a: { color: colors.core.grey[700] },
      '&:hover a': { color: colors.core.blue[800] },
      transition: 'color 0.2s ease-out',
    }}
    layout={animate}
    initial={animate ? { opacity: 0, scale: 0 } : {}}
    animate={animate ? { opacity: 1, scale: 1 } : {}}
    exit={animate ? { opacity: 0, scale: 0 } : {}}
    transition={animate ? { ease: 'easeOut', duration: 0.2 } : {}}
  >
    <Grid
      item
      container
      flexDirection={'column'}
      sx={{ flexBasis: 0, flexGrow: 1, mr: 1 }}
    >
      <Link
        href={job.url}
        underline={'none'}
        target={'_blank'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <Typography
          variant={'h5'}
          sx={{
            fontFamily: [
              foundersGrotesk.style.fontFamily,
              null,
              nantes.style.fontFamily,
            ],
            fontSize: ['1.25rem', null, '1.375rem'],
            lineHeight: 1.5,
          }}
        >
          {job.title}
        </Typography>
        <Typography
          sx={{
            fontFamily: foundersGrotesk.style.fontFamily,
            fontSize: ['1rem'],
            lineHeight: 1.5,
          }}
        >
          {job.location}
          <Typography
            component={'span'}
            sx={{
              px: 1,
              color: colors.core.grey[400],
              fontSize: ['1.125rem'],
              lineHeight: 1.35,
            }}
          >
            |
          </Typography>
          {job.office}
        </Typography>
      </Link>
    </Grid>
    <Link
      href={job.url}
      underline={'hover'}
      target={'_blank'}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Typography variant={'overline'}>VIEW JOB</Typography>
    </Link>
  </Grid>
)

export const TendAccordionItemPanel = styled(AccordionItemPanel)({
  animation: 'fadein 0.35s ease-in',
  '@keyframes fadein': {
    '0%': {
      opacity: 0,
      maxHeight: 0,
    },
    '100%': {
      opacity: 1,
      maxHeight: 5000,
    },
  },
  '@keyframes fadeout': {
    '0%': {
      opacity: 1,
      maxHeight: 5000,
    },
    '100%': {
      opacity: 0,
      maxHeight: 0,
      display: 'none',
    },
  },
  '&[hidden]:not(.initial)': {
    display: 'block',
    animation: 'fadeout 0.15s ease-out forwards',
  },
})

const JobListings = ({
  jobs,
  pageSize = 8,
  expanded = false,
}: JobListingsProps) => {
  const [groupedJobs, setGroupedJobs] = useState<JobsPerCategoryType[]>([])
  const [categoryParams, setCategoryParams] = useState<CategoryParamsType>({})

  useEffect(() => {
    //we use an object for cheap category reference
    const foundCategories: {
      [category: string]: JobsPerCategoryType
    } = {}
    jobs.forEach((job) => {
      const category = job.category
      if (foundCategories[category] === undefined) {
        foundCategories[category] = {
          category,
          uuid: category?.replace(/\s/g, '-'),
          jobs: [],
        }
      }

      foundCategories[category].jobs.push(job)
    })

    //we convert the foundCategories object to a sorted array
    const jobsPerCategory: JobsPerCategoryType[] =
      Object.values(foundCategories)

    jobsPerCategory.sort((a, b) => {
      const catA = a.category?.toUpperCase()
      const catB = b.category?.toUpperCase()

      return catA < catB ? -1 : catA > catB ? 1 : 0
    })

    setCategoryParams((categoryParams) => {
      const newCategoryParams = { ...categoryParams }

      jobsPerCategory.forEach((group) => {
        if (newCategoryParams[group.uuid] === undefined) {
          newCategoryParams[group.uuid] = {
            limit: pageSize,
            className: 'initial',
            expanded,
          }
        } else {
          newCategoryParams[group.uuid].className = 'initial'
        }
      })

      return newCategoryParams
    })

    setGroupedJobs(jobsPerCategory)
  }, [jobs, pageSize, expanded])

  const preExpanded = useMemo(() => {
    const preExpanded: string[] = []
    //we relay on the jobs property to fill in the pre expanded categories if the expanded prop is true,
    //because we have to fill preExpanded before setting the grouped jobs for it to work
    if (expanded) {
      jobs.forEach((job) => {
        const uuid = job.category?.replace(/\s/g, '-')
        if (!preExpanded.includes(uuid)) {
          preExpanded.push(uuid)
        }
      })
      return preExpanded
    }

    for (const category in categoryParams) {
      if (categoryParams[category].expanded) {
        preExpanded.push(category)
      }
    }

    return preExpanded
  }, [categoryParams, jobs, expanded])

  const onLoadMore = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setCategoryParams((categoryParams) => {
      if (event.target instanceof HTMLButtonElement) {
        const newCategoryParams = { ...categoryParams }

        const category = event.target.dataset.category
        const currentLimit = newCategoryParams[category].limit
        newCategoryParams[category].limit = currentLimit + pageSize
        return newCategoryParams
      }
      return categoryParams
    })
  }, [])

  const onToggleCategory = useCallback(
    (args?: ID[]) => {
      setCategoryParams((categoryParams) => {
        const newCategoryParams = { ...categoryParams }
        for (const category in newCategoryParams) {
          if (groupedJobs.some((group) => group.uuid === category)) {
            const expanded = args.includes(category)
            newCategoryParams[category].expanded = expanded
            if (expanded) {
              newCategoryParams[category].className = ''
            }
          }
        }

        return newCategoryParams
      })
    },
    [groupedJobs],
  )

  if (jobs.length === 0) {
    return (
      <Typography
        variant={'h5'}
        sx={{ pt: { xs: 2, md: 4 }, fontSize: '1.375rem' }}
      >
        {'Sorry, there are no openings that match that criteria.'}
      </Typography>
    )
  }

  return (
    <TendAccordion
      allowZeroExpanded
      allowMultipleExpanded
      preExpanded={preExpanded}
      style={{ overflow: 'hidden' }}
      onChange={onToggleCategory}
    >
      {groupedJobs.map((group) => {
        return (
          <Box
            key={group.category}
            component={motion.div}
            layout={true}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <TendAccordionItem
              uuid={group.uuid}
              sx={{ py: 2, borderBottom: '1px solid' }}
            >
              <TendAccordionSummary>
                <AnimatePresence initial={false}>
                  <Typography
                    key={`$group.category}-title`}
                    variant={'overline'}
                    sx={{
                      fontSize: 16,
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.375rem',
                    }}
                  >
                    {group.category}{' '}
                    <Typography
                      variant={'overline'}
                      component={'span'}
                      sx={{ fontSize: 16, fontWeight: 400 }}
                    >
                      {`(${group.jobs.length})`}
                    </Typography>
                  </Typography>
                </AnimatePresence>
              </TendAccordionSummary>
              <TendAccordionItemPanel
                className={categoryParams[group.uuid].className}
                style={{ overflow: 'hidden' }}
              >
                <Box
                  component={motion.ul}
                  sx={{ listStyle: 'none', pl: 0, m: 0 }}
                >
                  <AnimatePresence initial={false}>
                    {group.jobs
                      .slice(0, categoryParams[group.uuid].limit)
                      .map((job) => (
                        <JobItem key={job.id} job={job} animate={true} />
                      ))}
                  </AnimatePresence>
                </Box>
                {group.jobs.length > categoryParams[group.uuid].limit && (
                  <Button
                    variant={'outlined'}
                    color={'primary'}
                    sx={{ my: 2, lineHeight: 1.15 }}
                    onClick={onLoadMore}
                    data-category={group.uuid}
                  >
                    LOAD MORE
                  </Button>
                )}
              </TendAccordionItemPanel>
            </TendAccordionItem>
          </Box>
        )
      })}
    </TendAccordion>
  )
}

const JobBoard = ({
  title,
  subTitle,
  images,
  initialFilters = {},
  jobs,
  showCategoryFilter = true,
  onFilterChanged,
  divider = true,
  expanded = false,
}: JobBoardProps) => {
  const [filters, setFilters] = useState<JobFiltersType>({})

  useEffect(() => {
    setFilters(initialFilters)
  }, [initialFilters])

  const categories = useMemo(() => {
    if (!showCategoryFilter) {
      return
    }

    const foundCategories: string[] = []
    jobs.forEach((job) => {
      if (!foundCategories.includes(job.category)) {
        foundCategories.push(job.category)
      }
    })
    return foundCategories
  }, [jobs, showCategoryFilter])

  const locations = useMemo(() => {
    const foundLocations = []

    jobs.forEach((job) => {
      if (!foundLocations.includes(job.location)) {
        foundLocations.push(job.location)
      }
    })

    return foundLocations
  }, [jobs])

  const filteredJobs = useMemo(() => {
    let filteredJobs = jobs
    const { category, location, search } = filters

    if (category && category?.[0] !== '') {
      filteredJobs = filteredJobs.filter((job) =>
        category.includes(job.category),
      )
    }

    if (location && location?.[0] !== '') {
      filteredJobs = filteredJobs.filter((job) =>
        location.includes(job.location),
      )
    }

    if (search) {
      const searchRegEx = new RegExp(escapeRegExp(search), 'i')
      filteredJobs = filteredJobs.filter(
        (job) =>
          searchRegEx.test(job.title) ||
          searchRegEx.test(job.category) ||
          searchRegEx.test(job.location) ||
          searchRegEx.test(job.office),
      )
    }

    return filteredJobs
  }, [jobs, filters])

  const onChange = useCallback(
    (filters) => {
      setFilters(filters)
      onFilterChanged?.(filters)
    },
    [onFilterChanged],
  )

  return (
    <ScrollAnimate>
      <Box sx={{ pt: 5, pb: 4 }}>
        <Container maxWidth="xl">
          {divider ? (
            <Divider
              sx={{
                borderBottomWidth: 3,
                borderBottomColor: colors.core.grey[700],
              }}
            />
          ) : (
            <></>
          )}
          <Grid container sx={{ mt: { xs: 3, md: 5 } }}>
            <Grid xs={12} item md sx={{ pr: { xs: 0, md: 12 } }}>
              <Typography
                component="h2"
                variant="h3"
                sx={{
                  fontFamily: nantes.style.fontFamily,
                  color: colors.core.copper[600],
                  mb: 3,
                }}
              >
                {title}
              </Typography>
              <Grid
                item
                container
                rowGap={{ md: 8 }}
                md={8}
                direction={'column'}
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  position: 'relative',
                }}
              >
                {images?.map((img, index) => (
                  <Box key={img} sx={imageStyles[index]}>
                    <Img src={img} />
                  </Box>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md>
              <Typography
                sx={{
                  fontFamily: nantes.style.fontFamily,
                  fontSize: { xs: '1.25rem', md: '1.5rem' },
                  mb: 3,
                }}
              >
                {subTitle}
              </Typography>
              <Box sx={{ mb: { xs: 6, md: 5 } }}>
                <JobFilters
                  locations={locations}
                  categories={categories}
                  filters={filters}
                  onChange={onChange}
                />
              </Box>
              <JobListings jobs={filteredJobs} expanded={expanded} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ScrollAnimate>
  )
}

export default JobBoard
